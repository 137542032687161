import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';


export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'id', width: 140},
    { field: 'name', headerName: 'name', width: 600},
    { field: 'spocPerson', headerName: 'spocPerson', width: 200},
    { field: 'regNum', headerName: 'regNum', width: 140},
    { field: 'taxNum', headerName: 'taxNum  ', width: 140}
  ];

  const columnsAgreements: GridColDef[] = [
    { field: 'agreementId', headerName: 'agreementId', width: 100},
    { field: 'name', headerName: 'Name', width: 600 },
    { field: 'actualVersion', headerName: 'actualVersion', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDeleteAgrementClick(params.row.agreementId)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  
  const columnsCCIDs: GridColDef[] = [
    {
      field: "edit",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleCCIDOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'id', width: 100},
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'webURL', headerName: 'webURL', width: 200 },
    { field: 'agreementId', headerName: 'agreement', width: 100 },
    {
      field: 'actions',
      headerName: 'Delete',
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDeleteCCIDClick(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

    const [open, setOpen] = useState(false);
    const [openSubDialogAgreements, setOpenSubDialogAgreements] = useState(false);
    const [openSubDialogCCID, setOpenSubDialogCCID] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertText, setOpenAlertText] = useState('Cannot delete item, because it is in use.');

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [agreementsData, setAgreementsData] = useState([]);

    const [addAgreementItem, setAddAgreementItem] = useState('');
    const [addCCIDItem, setAddCCIDItem] = useState(Object);
    const [addCCIDAgreement, setAddCCIDAgreement] = useState({purposes:[],changePurpose :false});

    const [search, setSearch] = useState('');

    const [item, setItem] = useState(Object);
    
    const [tabValue, setTabValue] = React.useState('1');

    //HANDLE TAB CHANGE ON PARTNER DETAIL DIALOG
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    //HANDLE SEARCH CHANGE IN PARTNER LIST
    const changeSearch = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setSearch(event.target.value)
    }

    async function getNewId(): Promise<string> {
      try {
        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_NEWID_URL+'?type=ccid', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });    
  
        const data = await response.json();
        // Assuming the API response has a property named 'message'
        const apiMessage: string = data.id;
    
        return apiMessage;
      } catch (error) {
        console.error(`Error fetching data: ${error}`);
        throw error; // You may want to handle or log the error as needed
      }
    }

    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
      }
    };

    //LOAD DATA TO PARTNERS LIST
    // eslint-disable-next-line
    const loadData = async() => {

        console.log("button clicked "+search);

        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_PARTNERS_URL +"?search="+search, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (response.status) {
            const partners = await response.json();
            if (partners != null)
              setTableData(partners);
            console.log("data loaded.")
          }
    };

    const loadDetail = async(id:string) => {

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_PARTNER_URL +"/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status) {
          const partner = await response.json();
          console.log("detail data loaded.")
          console.log(partner)
          if (partner != null)
            setItem(partner);
        }
  };

    //LOAD AGREMENTS TO COMBO BOX FOR ADDING AGR. TO PARTNER
    const loadAgreements = async() => {

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_AGREEMENTS_URL+'?onlyActualOrLast=true', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status) {
          const agreements = await response.json();
          if (agreements != null)
            setAgreementsData(agreements);
          console.log("data loaded.")
        }
  };

    //AUTO LOAD AFTER DIALOG INITIALIZATION
    useEffect(() => {
      loadData();
      loadAgreements();
    // eslint-disable-next-line
    }, [])

        // OPEN PARTNER DIALOG
        const handleOpen = async (props:any) => {
          console.log('detail open')
          console.log(props)
          await loadDetail(props.id);
          setOpen(true);
        };
    
        //CLOSE PARTNER DIALOG
        const handleClose = () => {
          setOpen(false);
        };
    
        //SAVE PARTNER DIALOG
        const handleSave = async() => {
          
          const itemToSave = item;
    
          console.log(itemToSave);
    
          const token = await getAccessTokenSilently();
      
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + token },
            body: JSON.stringify(itemToSave)
            };
          await fetch(process.env.REACT_APP_PARTNER_URL, requestOptions);
          
          loadData();
          setOpen(false);
        }

        //DELETE AGREEMENT LINE IN PARTNER DETAL GRID
        const handleDeleteAgrementClick = (agreementId: number) => {
          
          //check if agreement is not used
          if (item.ccids.filter((item:any) => item.agreementId === agreementId).length > 0)
          {
            setOpenAlertText('Cannot delete item, because it is in use.')
            setOpenAlert(true);
            return;
          }


          const updatedAgreements = item.agreements.filter((item:any) => item.agreementId !== agreementId);
          setItem((prevState:any) => ({
            ...prevState,
              agreements: updatedAgreements
          }))
        };
    
        //DELETE CCID LINE IN PARNTER DETAIL GRID
        const handleDeleteCCIDClick = (id: number) => {
          // Delete logic: Filter out the item with the specified ID
          const updatedCCIDs = item.ccids.filter((item:any) => item.id !== id);
          setItem((prevState:any) => ({
            ...prevState,
              ccids: updatedCCIDs
          }))
        }; 
    
        // OPEN ADD AGREEMENT DIALOG
        const addAgreement = async() => {
          setAddAgreementItem('');
          setOpenSubDialogAgreements(true);
        };
    
        // CANCEL AGREEMENT DIALOG
        const handleAgreementClose = () => {
          setOpenSubDialogAgreements(false);
        };
    
        // SAVE ADD AGREEMENT DIALOG
        const handleAddAgreement = () => {
          //check if added item is not already added
          if (item.agreements.filter((item:any) => item.agreementId === addAgreementItem).length > 0)
          {
            setOpenAlertText('Cannot add item, because it is already added.')
            setOpenAlert(true);
            return;
          }

          const selectedAgreement:any = agreementsData.find((i:any) => i.agreementId === addAgreementItem);
          console.log(selectedAgreement)
          const newAgreements = [...item.agreements, {id:addAgreementItem, agreementId:addAgreementItem, name:selectedAgreement.name, actualVersion:selectedAgreement.actualVersion} as never];
          setItem((prevState:any) => ({
            ...prevState,
              agreements: newAgreements
          }))
          setOpenSubDialogAgreements(false);
        };
    
        // OPEN CCID DIALOG
        const handleCCIDOpen = (props:any) => {
          console.log(props)
          //filter agreement object on ccid agreementId
          const agreement = agreementsData.filter((i:any) => i.agreementId===props.agreementId)[0];
          console.log(agreement)
          setAddCCIDAgreement(agreement);
          setAddCCIDItem(props);
          setOpenSubDialogCCID(true);
        };
    
        // OPEN ADD CCID DIALOG
        const addCCID = async() => {
          const newId = await getNewId();
          console.log(newId);
          setAddCCIDItem({id:'new',newId:newId} as never);
          setOpenSubDialogCCID(true);
        };
    
        // CANCEL CCID DIALOG
        const handleCCIDClose = () => {
          setOpenSubDialogCCID(false);
        };
    
        // SAVE ADD CCID DIALOG
        const handleCCIDSave = () => {
          if (addCCIDItem.id === 'new')
          {
            addCCIDItem.id = addCCIDItem.newId;
            setItem((prevState:any) => ({
              ...prevState,
                ccids: [...item.ccids, addCCIDItem]
            }))
          }
          else
          {
              //updating item in table
              const updatedItems = item.ccids.map((i:any) =>
                  i.id === addCCIDItem.id ? { ...i, name: addCCIDItem.name, description: addCCIDItem.description, webURL: addCCIDItem.webURL, agreementId:addCCIDItem.agreementId, purposes:addCCIDItem.purposes } : i
                );
                setItem((prevState:any) => ({
                  ...prevState,
                    ccids: updatedItems
                }))
          }
          

          setOpenSubDialogCCID(false);
        };

        //HANDLE AGREEMENT CHANGE ON CCID DIALOG
        const handleCCIDAgreementChange = (event: SelectChangeEvent) => {

          const agreement = agreementsData.filter((i:any) => i.agreementId===event.target.value)[0] as any;
          setAddCCIDAgreement(agreement);
          
          const CCIDPurposes = [] as any;

          agreement.purposes.map((i:any) => { CCIDPurposes.push({code:i.code,selected:false, name:i.name}); return i; })

          setAddCCIDItem((prevState:any) => ({
              ...prevState,
              agreementId: event.target.value,
              purposes: CCIDPurposes
            }))
          console.log(CCIDPurposes);
        }

        //HANDLE PURPOSE CHECKBOX ON CCID DIALOG
        const handleCCIDCheckboxChange = (id:string, checked: boolean) => {
          console.log('id '+id + '  checked:'+checked)
          const newPurposes = addCCIDItem.purposes.map((item:any) => { 
              if(item.code === id) {
                return { ...item, selected: checked}
              }
              return item;
            })
          setAddCCIDItem((prevState:any) => ({
            ...prevState,
            purposes: newPurposes
          }))
          console.log(addCCIDItem)
        }

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Button variant="contained" onClick={() => handleOpen({id:'new', agreements:[],ccids:[]})} sx={{pr:2}}>New partner</Button>
                <TextField label="Search" id="txtSearch" value={search} onChange={changeSearch} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "600px" }}}
                   />
                <Button variant="contained" onClick={loadData}>Search</Button>
            </div> 
            <Box sx={{ width: '100%' }}>
                { tableData?.length === 0 ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 1000,
            maxHeight: 900
          }
        }}
      >
      <DialogTitle>Partner {item.id}</DialogTitle>
        <DialogContent dividers style={{height:'900px'}}>
        <TextField
                    sx={{ m: 1}}
                    label="Name"
                    placeholder="Name"
                    value={item.name}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    name: event.target.value,
                                                }))}
                />
        <TextField
                    sx={{ m: 1}}
                    label="Company Name"
                    placeholder="Copany Name"
                    value={item.company?.name}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    name: event.target.value,
                                                }))}
                />
        <TextField
                    sx={{ m: 1}}
                    label="Spoc person name"
                    placeholder="Spoc person"
                    value={item.spocPerson}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    spocPerson: event.target.value,
                                                }))}
                />
            <Grid container spacing={2} columns={2}>
              <Grid item xs={1}>
              <TextField
                    sx={{ m: 1}}
                    label="Registration number"
                    placeholder="Registration number"
                    value={item.regNum}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                        regNum: event.target.value,
                    }))}
                />
              </Grid>
              <Grid item xs={1}>
              <TextField
                    sx={{ m: 1}}
                    label="Tax number"
                    placeholder="Tax number"
                    value={item.taxNum}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                        taxNum: event.target.value,
                    }))}
                />
              </Grid>
            </Grid>
            <TextField
                    sx={{ m: 1}}
                    label="Description"
                    placeholder="Description"
                    value={item.description}
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                      ...prevState,
                        description: event.target.value,
                    }))}
                />

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Agreements" value="1" />
              <Tab label="CCIDs" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Button variant="contained" onClick={addAgreement}>Add</Button>
            <DataGrid
                rows={item.agreements}
                columns={columnsAgreements}
                pageSize={13}
                rowsPerPageOptions={[13]}
                disableSelectionOnClick
                autoHeight={true}
                experimentalFeatures={{ newEditingApi: true }}
            />
          </TabPanel>

          <TabPanel value="2">
              <Button variant="contained" onClick={addCCID} disabled={item.agreements?.length === 0}>Add</Button>
                <DataGrid
                    rows={item.ccids}
                    columns={columnsCCIDs}
                    pageSize={13}
                    rowsPerPageOptions={[13]}
                    disableSelectionOnClick
                    autoHeight={true}
                    experimentalFeatures={{ newEditingApi: true }}
                />
          </TabPanel>
        </TabContext>


        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSubDialogAgreements}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1020px!important",
            heigh: "900px",
            maxHeight:"900px!important"
          },
        }}
      >
      <DialogTitle>Partner {item.id} - add agreement</DialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ m: 1, minWidth: 600 }}>
              <InputLabel id="demo-select-small-label">Agreement</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addAgreementItem}
                autoWidth
                label="Type"
                onChange={(event: SelectChangeEvent) => setAddAgreementItem(event.target.value)}
              >
                {agreementsData.map((option:any) => (
                    <MenuItem value={option.agreementId} key={option.agreementId}>
                      {option.agreementId + ' ' + option.name+' ('+option.actualVersion+')'}
                    </MenuItem>
                ))}

              </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleAddAgreement}>Add</Button>
          <Button onClick={handleAgreementClose}>Close</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openSubDialogCCID}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1020px!important",
            heigh: "900px",
            maxHeight:"900px!important"
          },
        }}
      >
      <DialogTitle>Partner CCID {addCCIDItem.id}</DialogTitle>
        <DialogContent dividers>

        <TextField
                    sx={{ m: 1, minWidth: 800 }}
                    label="Name"
                    placeholder="Name"
                    value={addCCIDItem.name} 
                    onChange={(event:any) => setAddCCIDItem((prevState:any) => ({
                                                  ...prevState,
                                                    name: event.target.value,
                                                }))}
                />
        <TextField
                    sx={{ m: 1, minWidth: 800 }}
                    label="WEB URL"
                    placeholder="WEB URL"
                    value={addCCIDItem.webURL} 
                    onChange={(event:any) => setAddCCIDItem((prevState:any) => ({
                                                  ...prevState,
                                                  webURL: event.target.value,
                                                }))}
                />
        <TextField
                    sx={{ m: 1, minWidth: 800 }}
                    label="Cookie URL"
                    placeholder="Cookie URL"
                    helperText="Url pro uložení cookie, ve většině případů by měla být .domena.cz"
                    value={addCCIDItem.cookieURL} 
                    onChange={(event:any) => setAddCCIDItem((prevState:any) => ({
                                                  ...prevState,
                                                  cookieURL: event.target.value,
                                                }))}
                />
        <TextField
                    sx={{ m: 1, minWidth: 800 }}
                    label="Description"
                    placeholder="Description"
                    value={addCCIDItem.description}
                    onChange={(event:any) => setAddCCIDItem((prevState:any) => ({
                                                  ...prevState,
                                                  description: event.target.value,
                                                }))}
                />
          <FormControl sx={{ m: 1, minWidth: 800 }} >
              <InputLabel id="demo-select-small-label">Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addCCIDItem.agreementId}
                autoWidth
                label="Type"
                onChange={handleCCIDAgreementChange}
              >
                {item.agreements ? item.agreements.map((option:any) => (
                    <MenuItem value={option.agreementId} key={option.agreementId}>
                      {option.agreementId + ' ' + option.name+' ('+option.actualVersion+')'}
                    </MenuItem>
                )) : null }

              </Select>
          </FormControl>
          <FormGroup>
            
            Agreements purposes:
            {addCCIDItem.purposes?.map((checkbox:any) => (
              <FormControlLabel
                key={checkbox.code}
                disabled={addCCIDAgreement.changePurpose?(!addCCIDAgreement.changePurpose):true}
                control={
                  <Checkbox
                    id={checkbox.code}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCCIDCheckboxChange(checkbox.code, event.target.checked)}
                    checked={!addCCIDAgreement.changePurpose || checkbox.selected}
                  />
                }
                label={checkbox.code +' ' + (addCCIDAgreement.purposes?.filter((item:any)=>(item.code===checkbox.code))[0] as any)?.name}
              />
            ))}
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleCCIDSave}>Save</Button>
          <Button onClick={handleCCIDClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {openAlertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}